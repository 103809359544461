* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

:global(.marker) {
  background-repeat: no-repeat;
}

:global(.countryNameMarker) {
  background-color: #333333;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 7px;
}

/* Popup for France */
:global(.maplibregl-popup-content) {
  background-color: #ffffff;
  border-radius: 5px;
  color: #000000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  h2 {
    font-size: 1.2em;
    padding: 0;
    margin: 0;
  }

  span {
    display: block;
    width: 20px;
    height: 3px;
    background-color: #2c4965;
    margin: 5px 0;
  }
}

/* Popup for countries */
:global(.maplibregl-popup-anchor-left .maplibregl-popup-tip) {
  border-top-color: #ffffff;
}

:global(
    .maplibregl-popup.centroid-popup.maplibregl-popup-anchor-left
      .maplibregl-popup-content
  ) {
  background-color: #ffda00;
  color: #2c4965;
  border-radius: 20px;
  padding: 0;
  padding-left: 2px;
  width: 20px;
  transition: width 0.7s ease;
  white-space: nowrap;

  :first-child {
    min-height: 13px;
    img {
      width: 13px;
      height: 13px;
      margin-right: 2px;
      align-self: center;
    }

    h2 {
      min-width: 100%;
      opacity: 0;
      transition: opacity 1.2s ease;
    }

    span {
      margin-left: 25px;
      margin-top: -5px;
    }

    :first-child {
      display: flex;
    }
  }
}

:global(
    .maplibregl-popup.centroid-popup.maplibregl-popup-anchor-left
      .maplibregl-popup-content:hover
  ) {
  cursor: pointer;
  width: 100%;

  :first-child {
    h2 {
      opacity: 1;
    }
  }
}

:global(
    .maplibregl-popup.centroid-popup.maplibregl-popup-anchor-left.transition
      .maplibregl-popup-content
  ) {
  cursor: pointer;
  width: 100% !important;
  padding-right: 7px;

  :first-child {
    h2 {
      opacity: 1;
    }
  }
}

:global(
    .maplibregl-popup.centroid-popup.maplibregl-popup-anchor-left
      .maplibregl-popup-tip
  ) {
  border-top-color: transparent;
  border-right-color: transparent;
}
